import 'normalize.css';
import './TalentDetailTemplate.scss';

import { graphql } from "gatsby";
import React, { FC, useContext, useEffect, useRef, useState } from "react";

import  Layout  from '../../components/Layout'
import SEO from '../../components/SEO'
import NavContext, { NavProvider } from '../../components/Layout/Nav/NavProvider';

import SectionHeader from '../../components/SectionHeader';
import Slider from '../../components/Slider';
import HomeSliderContact from '../../components/HomeSliderContact';
import TalentDetail from '../../components/TalentDetail';
import { useInView } from '../../hooks/useInView';
import i18next from "i18next";
import { getSectionSlug, PageTemplateProps, YouplanetSections } from '../../constants/general';
import { ProjectType } from 'models/project.model';
import { Talent } from 'models/talent.model';
import { getCurrentLanguage } from '../../i18n';

const TalentTemplate: FC<PageTemplateProps> = ({ data, pageContext }) => {
  const { projectList } = data as any;
  const talent: Talent = data.talent;

  const projects = projectList.nodes;
  const projectsWithOwnerships: ProjectType[] = projects.filter(p => !!p.talentOwners);
  const talentProjects = projectsWithOwnerships.filter(p => p.talentOwners.some(t => t.id === pageContext.id))

  const currentLanguage = getCurrentLanguage();
  const currentSlug: string = pageContext.slug;

  const pageTitle = `${i18next.t('talents.pageTitle')} Youplanet - ${talent.name}`;
  const pageDescription = `${i18next.t('talents.pageTitle')} Youplanet - ${talent.name}`;
  const alternativeUrls = pageContext.alternativeUrls;

  const section = useRef<HTMLDivElement>();
  const inView = useInView(section, {}, true);
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);

  const { isMobile } = useContext(NavContext);

  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile])

  const goBackLink = () => {
    return `${getSectionSlug(YouplanetSections.TalentList, currentLanguage)}`;
  };

  function pageContent() {
    const elements: JSX.Element[] = [
      <TalentDetail talent={talent} goBackLink={goBackLink()} inView={inView} hasRelatedProjects={talentProjects?.length > 0} />,
      <HomeSliderContact />,
    ];
    return elements.map((el, index) => <span key={`${index}-talentDetailTemplate`}>{el}</span>);
  }

  return <NavProvider>
    <Layout
      language={currentLanguage}
      alternativeUrls={alternativeUrls}
      isHome={false}
    >
      <SEO
        title={pageTitle}
        description={pageDescription}
        lang={currentLanguage}
        slug={currentSlug}
      />
      <SectionHeader inView={true} />
      <section ref={section} className={`talent-section ${inView ? 'talent-section--in-view' : ''}`}>        
        {isMobileActive ? pageContent()
          : 
        <Slider hidePagination>
            {pageContent()}
        </Slider>        
        }
      </section>
    </Layout>
  </NavProvider>
};

export default TalentTemplate;

export const query = graphql`
query TalentTemplateQuery($id: String!, $lang: String) {
  talent: sanityTalent(id: { eq: $id }) {
    name
    shortName
    slug {
      current
    }
    category {
      name
    }
    socialLinks {
			name
      url
  	}
    description: _rawDescription
    image {
      ...ImageFragment
    }
    imageDetail {
      ...ImageFragment
    }
  },
  projectList: allSanityProject(
    sort: { order: ASC, fields: [position] }
    filter: { i18n_lang: { eq: $lang }, _id: { regex: "/^(?!drafts.).*/" } }
  ) {
    nodes {
      name
      talentOwners { 
        id
        name
      }
    }
  },
  locales: allLocale(filter: { language: { eq: $lang } }) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;
