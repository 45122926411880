import "./TalentDetail.scss";

import React, { FC, useContext, useEffect, useState } from "react";
import Button from "../Button";
import ArrowMoreContent from "../ArrowMoreContent";
import DataPanel from "../DataPanel";
import DataPanelItem from "../DataPanel/DataPanelItem";
import Image from "../Image";
import { PortableText } from "@portabletext/react";
import { t } from "i18next";
import { getCurrentLanguage } from "../../i18n";
import NavContext from "../Layout/Nav/NavProvider";
import { FilterUrlConstants, getSectionSlug, YouplanetSections } from "../../constants/general";
import { Talent } from '../../models/talent.model';

export interface TalentDetailProps {
  talent: Talent;
  goBackLink: string;
  inView: boolean;
  hasRelatedProjects: boolean;
}

const TalentDetail: FC<TalentDetailProps> = ({
  talent,
  goBackLink,
  inView,
  hasRelatedProjects,
}) => {
  const { isMobile } = useContext(NavContext);
  const [isMobileSize, setIsMobileSize] = useState(false);
  const currentLanguage = getCurrentLanguage();

  useEffect(() => {
    const state = isMobile();
    if (isMobileSize === state) {
      return;
    }

    setIsMobileSize(state);
  }, [isMobile]);

  function buildProjectsByTalentOwnerUrl(name: string) {
    const params = `?${FilterUrlConstants.TalentOwnerName}=${name}`;

    const url = getSectionSlug(YouplanetSections.ProjectList, currentLanguage, params );
    return url;
  }

  const contactButton = () => {
    return (
      <>
        <Button
          isLink={true}
          uppercase={true}
          shortPadding={true}
          solid={true}
          href={getSectionSlug(YouplanetSections.Contact, currentLanguage)}
        >
          {t("talentDetail.workWith", { talent: talent.shortName })}
        </Button>
        {hasRelatedProjects && (
          <Button
            isLink={true}
            uppercase={true}
            href={buildProjectsByTalentOwnerUrl(talent.name)}
          >
            {t("talentDetail.discoverLastProjects")}
          </Button>
        )}
      </>
    );
  };

  return (
    <div className={`talent-detail ${inView ? "talent-detail--in-view" : ""}`}>
      <div className="talent-detail__data-content">
        <a href={goBackLink} className="talent-detail__link">
          <ArrowMoreContent
            containerHeight="2rem"
            containerWidth="16rem"
            sideWidth="1rem"
            width=".2rem"
            direction="left"
            clickHandler={null}
          />
        </a>
        <div className="talent-detail__col">
          <div className="">
            <h1 className="talent-detail__h1">
              <span className="talent-detail__text">{talent.name}</span>
            </h1>
            <div className="talent-detail__data">
              <DataPanel>
                <DataPanelItem
                  title={t("talentDetail.categories")}
                  alignedToRight
                >
                  {talent.category.filter(c => !!c).map((category, index) => (
                    <span key={`category-${category.name}-${index}`}>
                      {category.name}
                    </span>
                  ))}
                </DataPanelItem>
                <DataPanelItem
                  title={t("talentDetail.platforms")}
                  alignedToRight
                >
                  {talent.socialLinks.map((link) => {
                    return (
                      <a
                        key={`social-link-${link.name}`}
                        href={link.url}
                        target="_blank"
                      >
                        {link.name}
                      </a>
                    );
                  })}
                </DataPanelItem>
              </DataPanel>
            </div>
          </div>

          <div className="talent-detail__data-image">
            <Image
              image={talent.imageDetail || talent.image}
              title={talent.name}
              className="talent-detail__image cursor-click"
              data-cursor-text=" "
              data-cursor-rotate-text={talent.shortName}
            />
          </div>
        </div>
        <div className="talent-detail__buttons">{contactButton()}</div>
      </div>
      <div
        className={`talent-detail__description  scrollable custom-scrollbar cursor-click`}
        data-cursor-text="Scroll"
        data-cursor-text-color="#fff"
      >
        <PortableText value={talent.description} />
      </div>
      <div className="talent-detail__buttons is-mobile">{contactButton()}</div>
    </div>
  );
};

export default TalentDetail;
